/**
 * Carousel Flickity
 * ======================================
 * - https://flickity.metafizzy.co/
 */

import Flickity from "flickity";

const items = ".js-carousel-reviews";
const SETTINGS = {
  cellAlign: "center",
  pageDots: false,
  wrapAround: true,
  contain: true
};

class CarouselCategories {
  constructor() {
    this.elements = document.querySelectorAll(items);
    if (!this.elements) {
      return false;
    }

    this.elements.forEach((el) => {
      new Flickity(el, SETTINGS);
    });
  }
}

new CarouselCategories();
