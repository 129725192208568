
/**
 * Toggle Nav
 * ======================================
 * - toggle class on body
 */

const ELEMENTS = ".js-nav";
const TOGGLE_CLASS = "nav-is-open";

class ToggleNav {
  constructor() {
    this.elements = document.querySelectorAll(ELEMENTS);

    if (!this.elements) {
      return false;
    }

    this.elements.forEach((el) => {
      el.addEventListener("touchstart", this.toggleNav, {passive: true});
    });
  }

  toggleNav() {
    document.body.classList.toggle(TOGGLE_CLASS);
    document.body.classList.toggle("lock");
  }
}

new ToggleNav();
