/**
 * StickyTopbar
 * ======================================
 * - toggle class on body when element is sticky
 * - enable on some resolution only
 */

const ENABLE_ON = true;
const ELEMENT = ".js-stickytopbar";
const STICKY_CLASS = "topbar-is-sticky";
const PLACEHOLDER = true;
const PLACEHOLDER_CLASS = 'js-stickytopbar-placeholder';

class StickyTopbar {
  constructor() {
    this.element = document.querySelector(ELEMENT);

    if (!ENABLE_ON || !this.element) {
      return false;
    }

    this.offsetTop = this.element.offsetTop;
    this.offsetLeft = this.element.offsetLeft;

    if(PLACEHOLDER){
      this.placeholderEl = document.createElement('div');
      this.placeholderEl.classList.add(PLACEHOLDER_CLASS, 'hide');
      this.placeholderEl.style.height = this.element.offsetHeight + 'px';

      this.element.parentNode.insertBefore(this.placeholderEl, this.element.nextSibling);
    }

    document.addEventListener("scroll", this.bindScroll, false);
  }

  bindScroll = (e) => {
    const top = document.documentElement.scrollTop;

    if (top > this.offsetTop) {
      document.body.classList.add(STICKY_CLASS);
      this.element.style.left = this.offsetLeft;
      this.showPlaceholder(true)
    } else {
      document.body.classList.remove(STICKY_CLASS);
      this.showPlaceholder(false)
    }
  };

  showPlaceholder = (show) => {
    if(show) {
      this.placeholderEl.classList.remove('hide')
    } else {
      this.placeholderEl.classList.add('hide')
    }
  }
}

new StickyTopbar();
