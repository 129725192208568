require('./frontend/ToggleNav');
require('./frontend/ScrollClass');
require('./frontend/CarouselReviews');
require('./frontend/StickyTopbar');
require('./frontend/minicart');
require('./frontend/Glightbox');
require('./frontend/countup');
require('./frontend/ecomail');

// Fix for strange URLs on thank you page
window.addEventListener('DOMContentLoaded', function () {
  document.querySelectorAll('a[data-rewrite]').forEach(function (link) {
    link.setAttribute('href', link.dataset.rewrite);
  })
});

jQuery(document).ready(function ($) {
  $('.js-product-btn-wrapper .ajax_add_to_cart').click(function (e) {
    if (!dataLayer) {
      return;
    }

    let productDataElement = $(this).siblings('.gtm4wp_productdata');
    if (!productDataElement) {
      return;
    }
    let productData = productDataElement.data('gtm4wp_product_data');
    if (!productData) {
      return;
    }
    productData.price = productData.price.toString();

    dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        currency: gtm4wp_currency,
        value: productData.price,
        items: [
          productData
        ]
      },
    });
  });
});