const forms = document.getElementsByClassName("ecomail__subscribe");
if (forms.length) {

  Array.from(forms).forEach((el) => {
    el.addEventListener('submit', function (e) {
      e.preventDefault();
      const loader = el.getElementsByClassName('ld-ext-right')[0];
      loader.classList.add('running');
      const resultDiv = el.getElementsByClassName('ecomail__subscribe--result')[0];
      resultDiv.innerHTML = '';
      const data = new FormData(this);

      fetch(dogsieFrontend.restUrl + '/ecomail/', {method: 'post', body: data})
        .then((response) => {
          loader.classList.remove('running');
          if (response.status === 200) { // Or what ever you want to check
            return Promise.resolve(response.json()); // This will end up in SUCCESS part
          }
          return Promise.resolve(response.json()).then((responseInJson) => { // This will end up in ERROR part
            return Promise.reject(responseInJson.message); //  responseInJson.message = "Some nasty error message!"
          });
        })
        .then((result) => { // SUCCESS part
          resultDiv.innerHTML = result.message;
          console.log("Success: ", result.message); // Response from api in json
        }, (error) => { // ERROR part
          resultDiv.innerHTML = error;
        })
        .catch(catchError => {
          console.log("Catch: ", catchError);
        })
    })
  });

}
