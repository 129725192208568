const minicart = document.getElementsByClassName('mini-cart__price');
if (minicart.length) {
  fetch(`${dogsieFrontend.restUrl}/mini-cart?${Math.random().toString(36).substring(7)}`, {
    method: 'get',
    headers: {
      'X-WP-Nonce': dogsieFrontend.nonce // here you used the wrong name
    }
  })
    .then((response) => {
      if (response.status === 200) { // Or what ever you want to check
        return Promise.resolve(response.json()); // This will end up in SUCCESS part
      }
    })
    .then((result) => {
      minicart[0].innerHTML = result?.price ? result?.price : dogsieFrontend.basePrice
    })
}

