import {CountUp} from "countup.js";

const options = {
    separator: ' ',
    decimal: ',',
    duration: 4,
    enableScrollSpy: true,
    scrollSpyDelay: 250,
};

class Countup {
    constructor() {
        this.elements = document.querySelectorAll('.js-countup');
        if (!this.elements) {
            return false;
        }

        this.elements.forEach((element) => {
            new CountUp(element, element.dataset.value, options);
        });
    }
}

new Countup();
